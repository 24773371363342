const GameAction = {
    DRAW: 'DRAW',
    PICK_FROM_DISCARD_PILE: 'PICK_FROM_DISCARD_PILE',
    DISCARD: 'DISCARD',
    MELD: 'MELD',
    UNMELD: 'UNMELD',
    UNMELD_ALL: 'UNMELD_ALL',
    JOIN_ROUND: 'JOIN_ROUND',
    SET_READY: 'SET_READY',
    START_BREAK: 'START_BREAK',
    FINISH_BREAK: 'FINISH_BREAK',
    ASK_PARTNER_TO_GO_OUT: 'ASK_PARTNER_TO_GO_OUT',
    ANSWER_PARTNER_TO_GO_OUT: 'ANSWER_PARTNER_TO_GO_OUT',
    MARK_ROUND_STALEMATE: 'MARK_ROUND_STALEMATE',
    CANCEL_MARK_ROUND_STALEMATE: 'CANCEL_MARK_ROUND_STALEMATE',
};

let lastActionId = 0;
export function getNextActionId() {
    let actionId =  Date.now();
    if (actionId <= lastActionId) {
        actionId = lastActionId + 1;
    }
    lastActionId = actionId;
    return actionId;
}

export default GameAction;


let gameActionLock = false;
let gameActionLockQueue = [];
export function acquireGameActionLock() {
    return new Promise((resolve) => {
        if (gameActionLock) {
            gameActionLockQueue.push(resolve);
        } else {
            gameActionLock = true;
            resolve();
        }
    });
}

export function releaseGameActionLock() {
    if (gameActionLockQueue.length > 0) {
        gameActionLockQueue.shift()();
    } else {
        gameActionLock = false;
    }
}

export async function withGameActionLock(fn) {
    try {
        await acquireGameActionLock();
        await fn();
    } finally {
        releaseGameActionLock();
    }
}