import { createSlice } from "@reduxjs/toolkit";
import Api from "../../services/Api";
import { emulateGameAction } from "../../game/engine";
import { DEBUG } from "../../env.json";
import { withGameActionLock } from "../../game/GameAction";

const MAX_SEEN_LOCAL_ACTION_IDS = 256;

const gameSlice = createSlice({
    name: 'game',
    initialState: {
        game: null,
        settings: {},
        lastActionId: null,
        loading: false,
        isPerformingAction: false,
        error: null,
        lastLocalActionId: null,
    },
    reducers: {
        requestViewerGame: {
            reducer(state, action) {
                state.loading = !action.payload.isRefresh;
                state.isPerformingAction = action.payload.isPerformingAction ? action.payload.isPerformingAction : state.isPerformingAction;
                state.error = null;
            },
            prepare(isRefresh, isPerformingAction, skipSubscriptionMiddleware) {
                return { payload: { isRefresh, isPerformingAction, skipSubscriptionMiddleware: skipSubscriptionMiddleware } }
            }
        },
        receiveViewerGame: {
            reducer(state, action) {
                if (DEBUG) {
                    console.log('receiveViewerGame', {
                        state: Object.assign({}, state),
                        action: Object.assign({}, action),
                    });
                }

                if (action.payload.actionId && action.payload.actionId < state.lastLocalActionId) {
                    const isViewerTurn = !!action.payload.game.gameTeams.find(
                        team => team.teamPlayers.find(tp => tp.isViewer && tp.isCurrentTurn)
                    );

                    if (state.lastActionId !== action.payload.actionId && isViewerTurn) {
                        if (DEBUG) {
                            console.log('rejecting action locally', {
                                state: Object.assign({}, state),
                                action: Object.assign({}, action),
                                isViewerTurn: isViewerTurn,
                            });
                        }
                        return;
                    } else {
                        if (DEBUG) {
                            console.log('accepting latest action locally', {
                                state: Object.assign({}, state),
                                action: Object.assign({}, action),
                                isViewerTurn: isViewerTurn,
                            });
                        }
                    }
                } else if (action.payload.actionId && action.payload.isLocalAction) {
                    state.lastLocalActionId = Math.max(action.payload.actionId, state.lastLocalActionId || 0);
                }

                state.game = action.payload.game;
                if (action.payload.actionId !== null) {
                    state.lastActionId = action.payload.actionId;
                }

                state.loading = false;
                state.isPerformingAction = action.payload.isFromAction ? false : state.isPerformingAction;
                state.error = null;
            },
            prepare(game, isFromAction, actionId, isLocalAction) {
                return { payload: { game, isFromAction, actionId, isLocalAction: !!isLocalAction } };
            }
        },
        receiveFailedViewerGame: {
            reducer(state, action) {
                state.game = null;
                if (action.payload.actionId !== null) {
                    state.lastActionId = action.payload.actionId;
                }
                state.loading = false;
                state.isPerformingAction = action.payload.isFromAction ? false : state.isPerformingAction;
                state.error = action.payload.error;
            },
            prepare(error, actionId) {
                return { payload: { error, actionId } };
            }
        },
        requestPerformGameAction: {
            reducer(state, action) {
                state.isPerformingAction = true;
                state.error = null;
            }
        },
        receivePerformGameAction: {
            reducer(state, action) {
                state.error = null;
            }
        },
        receiveFailedPerformGameAction: {
            reducer(state, action) {
                state.isPerformingAction = false;
                state.error = null;
            }
        },
        applyPartialState: {
            reducer(state, action) {

                if (state.game.alphanumericId !== action.payload.partialState.alphanumericId) {
                    return;
                }

                if (action.payload.partialState.isRoundFinished) {
                    return;
                }

                const partialState = action.payload.partialState;
                state.game.isRoundFinished = partialState.isRoundFinished;
                state.game.drawPileTotalCards = partialState.drawPileTotalCards;
                state.game.discardPileTotalCards = partialState.discardPileTotalCards;
                state.game.discardPileTopCard = partialState.discardPileTopCard;

                partialState.teams.forEach((t) => {
                    const stateTeam = state.game.gameTeams.find((gt) => gt.id === t.id);
                    stateTeam.naturalCanastasLeft = t.naturalCanastasLeft;
                    stateTeam.unnaturalCanastasLeft = t.unnaturalCanastasLeft;
                    stateTeam.partialCurrentScore = t.partialCurrentScore;

                    t.players.forEach((p) => {
                        const statePlayer = stateTeam.teamPlayers.find((tp) => tp.id === p.id);
                        statePlayer.isCurrentTurn = p.isCurrentTurn;
                        statePlayer.isNextTurn = p.isNextTurn;
                        statePlayer.drawnInTurn = p.drawnInTurn;
                        statePlayer.isPlayingFoot = p.isPlayingFoot;
                        statePlayer.pickedUpPileInTurn = p.pickedUpPileInTurn;
                        statePlayer.hand = p.hand;
                    });

                    stateTeam.melds = t.melds;
                });

            },
            prepare(partialState) {
                return { payload: { partialState } };
            }
        }
    }
})

export const {
    requestViewerGame,
    receiveViewerGame,
    receiveFailedViewerGame,
    requestPerformGameAction,
    receivePerformGameAction,
    receiveFailedPerformGameAction,
    applyPartialState,
} = gameSlice.actions;

export default gameSlice.reducer;

export const fetchViewerGame = (gameAlphanumericId, isRefresh = false, isPerformingAction = false, actionId = null, skipSubscriptionMiddleware = false) => async (dispatch, getState) => {
    dispatch(requestViewerGame(isRefresh, isPerformingAction, skipSubscriptionMiddleware))

    const state = getState();
    try {
        const game = await Api.viewerGame(state.auth.login.accessToken, gameAlphanumericId);
        dispatch(receiveViewerGame(game, isPerformingAction, actionId));
    } catch (e) {
        dispatch(receiveFailedViewerGame(e.message, isPerformingAction, actionId));
    }
};

export const performGameAction = (gameAlphanumericId, action, cardIds, extraArgs, actionId, onActionFinished) => async (dispatch, getState) => {
    dispatch(requestPerformGameAction())

    const state = getState();
    try {
        try {
            const emulatedGame = emulateGameAction(state.viewer.game.game, action, cardIds, extraArgs);
            if (emulatedGame) {
                dispatch(receiveViewerGame(emulatedGame, true, actionId, true));
                if (onActionFinished) {
                    onActionFinished();
                }
            }
        } catch (err) {
            console.error('error emulating game action: %s', action, err);
        }

        await withGameActionLock(async () => {
            const game = await Api.performGameAction(state.auth.login.accessToken, gameAlphanumericId, action, cardIds, extraArgs);
            dispatch(receivePerformGameAction());
            if (!game.hasPartialStates) {
                dispatch(fetchViewerGame(gameAlphanumericId, true, true, actionId));
            }
        });
    } catch (e) {
        console.error(e);
        dispatch(receiveFailedPerformGameAction(e.message));
    }
};

export const applyPartialStates = (partialStates, actionId = null) => async (dispatch, getState) => {
    let ttl = 0;
    for (let i = 0; i < partialStates.length; i++) {
        ttl += (i > 0 ? partialStates[i - 1].ttl : 0);
        setTimeout(() => {
            dispatch(applyPartialState(partialStates[i]));
        }, ttl);

        if (i === partialStates.length - 1) {
            setTimeout(() => {
                const state = getState();
                dispatch(fetchViewerGame(state.viewer.game.game.alphanumericId, true, true, actionId));
            }, ttl + partialStates[i].ttl);

        }
    }
};
