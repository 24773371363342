import React from 'react';
import {ScrollView, View} from 'react-native';
import GameHelper from '../GameHelper';
import FlagIcon from '../../icons/FlagIcon';
import Logo from '../../icons/Logo';
import LoadingButton from '../../sharedComponents/loadingButton/LoadingButton';
import StandardText from '../../sharedComponents/standardText/StandardText';
import Colors from "../../styles/colors";
import Spacing from '../../styles/spacing';
import ScoreTable from './ScoreTable';
import IconButton from "../../sharedComponents/iconButton/IconButton";
import HomeIcon from "../../icons/HomeIcon";
import { APP_VERSION, DEBUG } from "../../env.json";

const ScoreBoard = ({ game, onStartRoundPress = () => { }, onGoHomePress = () => { }, onLeaderboardPress = () => {} }) => {

    const viewerTeamTotalScore = GameHelper.getViewerTeam(game).gameTeamScores.reduce((acc, score) => score.bonusCount + score.baseCount + score.secondCount, 0);
    const currentRoundPoints = GameHelper.getRoundPoints(game.currentRound);
    const previousRoundPoints = GameHelper.getRoundPoints(game.previousRound);

    let leadingTeamScore = null;
    let leadingTeamIndex = null;
    const totalScores = game.gameTeams.map((team, i) => {
        const teamTotalScore = team.gameTeamScores.reduce((acc, gts) => acc + gts.baseCount + gts.secondCount + gts.bonusCount, 0);
        if (leadingTeamScore === null || teamTotalScore > leadingTeamScore) {
            leadingTeamScore = teamTotalScore;
            leadingTeamIndex = i;
        }

        return teamTotalScore;
    });

    const addThousandsCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
        <ScrollView contentContainerStyle={{ ...ownStyles.scoreBoardContainer }}>
            <View style={{position: 'absolute', top: 15, left: 15, zIndex: 1}}>
                <IconButton icon={<HomeIcon size={24} color={Colors.onBackground} />} onPress={onGoHomePress} />
            </View>

            <ScrollView contentContainerStyle={{ alignItems: 'center' }}>
                <View style={{ ...ownStyles.scoreBoardWrapper }}>
                    <View style={{ ...ownStyles.scoreDetailsContainer, paddingTop: 40 }}>

                        <Logo size={95} />

                        <StandardText style={{ fontSize: 16, color: Colors.onBackground }} weight={700}>TOTAL SCORE</StandardText>

                        {game.hasEnded ? (
                            <StandardText style={{ fontSize: 72, color: Colors.onBackground }}>
                                {game.gameTeams[leadingTeamIndex].id === GameHelper.getViewerTeam(game).id ? 'YOU WIN' : 'YOU LOSE'}
                            </StandardText>
                        ) : (
                                <StandardText style={{ fontSize: 72, color: Colors.onBackground }}>{addThousandsCommas(viewerTeamTotalScore)}</StandardText>
                            )}

                        {!game.hasEnded &&
                            <StandardText style={{ fontSize: 16, color: Colors.onBackground }}>{`Round ${previousRoundPoints}`}</StandardText>
                        }

                        <View style={{marginTop: 5}}>
                            <StandardText style={{fontSize: 14, color: Colors.onBackground3}}>
                                Game ID: {game.id} {DEBUG && (<> / {APP_VERSION}</>)}
                            </StandardText>
                        </View>

                        <View style={{ marginTop: 40, alignSelf: 'stretch' }}>
                            <ScoreTable game={game} />
                        </View>
                    </View>
                </View>
            </ScrollView>

            <View style={{ ...ownStyles.totalContainer }}>
                <View style={{ ...ownStyles.scoreBoardWrapper }}>
                    <View style={{ ...ownStyles.tableRow }}>
                        <View style={{ ...ownStyles.tableColumn, textAlign: 'left' }}>
                            <StandardText style={{ ...ownStyles.totalCellText }}>Total</StandardText>
                        </View>
                        {totalScores.map((totalScore, i) => (
                            <View key={i} style={{ ...ownStyles.tableColumn, flexDirection: 'row', justifyContent: 'center' }}>
                                <StandardText style={{ ...ownStyles.totalCellText, ...(leadingTeamIndex === i ? { color: Colors.success2 } : {}), ...(game.hasEnded && leadingTeamIndex === i ? { marginLeft: 25 } : {}) }}>{addThousandsCommas(totalScore)}</StandardText>
                                {(game.hasEnded && leadingTeamIndex === i) && (
                                    <View style={{ marginLeft: 5 }}>
                                        <FlagIcon size={20} color={leadingTeamIndex === i ? Colors.success2 : Colors.success3} />
                                    </View>
                                )}
                            </View>
                        ))}
                    </View>
                    {game.hasEnded ? (
                        <View style={{width: '100%', flexDirection: 'row', justifyContent: 'stretch'}}>
                            <View style={{flex: 1}}>
                                <LoadingButton onPress={onGoHomePress} label={'Home'} smallChevron={true} />
                            </View>

                            {game.duplicateConfigGame && (
                            <View style={{flex: 1, marginStart: 10}}>
                                <LoadingButton onPress={onLeaderboardPress} label={'Leaderboard'} backgroundColor={Colors.tournamentColor} showChevron={true} smallChevron={true} />
                            </View>
                            )}
                        </View>
                    ) : (
                            <LoadingButton onPress={onStartRoundPress} disabled={GameHelper.getViewerPlayer(game).joinedRound} label={GameHelper.getViewerPlayer(game).joinedRound ? `Waiting for other players...` : `Start round of ${currentRoundPoints}`} />
                        )
                    }
                </View>
            </View>
        </ScrollView>
    );
};

const ownStyles = {
    scoreBoardContainer: {
        flex: 1,
        alignItems: 'stretch'
    },
    scoreBoardWrapper: {
        width: '100%',
        maxWidth: 450,
        paddingStart: 20,
        paddingEnd: 20,
    },
    scoreDetailsContainer: {
        flex: 1,
        alignItems: 'center',
        paddingBottom: 130
    },
    totalContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: Colors.inverseBackground,
        alignItems: 'center',
        padding: Spacing.large
    },
    tableRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: Spacing.large,
        paddingLeft: Spacing.base,
        paddingRight: Spacing.base,
        borderRadius: 2
    },
    tableColumn: {
        flex: 1,
        textAlign: 'center',
    },
    totalCellText: {
        color: Colors.background,
        fontSize: 20,
        weight: 500
    }
};

export default React.memo(ScoreBoard);
