import React, {useEffect, useRef, useState} from 'react';
import {ScrollView, TouchableOpacity, View, Image} from 'react-native';
import FlagIcon from '../../icons/FlagIcon';
import StandardText from '../../sharedComponents/standardText/StandardText';
import Colors from "../../styles/colors";
import Spacing from '../../styles/spacing';
import ScoreTable from './ScoreTable';
import IconButton from "../../sharedComponents/iconButton/IconButton";
import HomeIcon from "../../icons/HomeIcon";
import CrossIcon from "../../icons/CrossIcon";
import GamesList from "./GamesList";
import {fetchViewerGames} from "../../redux/viewer/actions/games";
import {connect} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import InfoIcon from "../../icons/InfoIcon";
import GameConfigSummary from "./GameConfigSummary";
import tutorialIcon from '../../../assets/tutorial-icon.png';
import TutorialIcon from "../../icons/TutorialIcon";
import Analytics, {EVENTS} from "../../services/Analytics";
import GameHelper from '../GameHelper';
import { APP_VERSION, DEBUG } from "../../env.json";


const ScoreBoardInGame = ({
                              accessToken,
                              dispatch,
                              game,
                              games,
                              cursor,
                              gamesLoading,
                              showRulesDirectly = false,
                              onStartRoundPress = () => {
                              },
                              onClosePress = () => {
                              },
                              onGoHomePress = () => {
                              },
                              onGameSwitched = () => {
                              }
                          }) => {

    const navigation = useNavigation();
    const [initialFetchTriggered, setInitialFetchTriggered] = useState(false);
    const [showRules, setShowRules] = useState(null);
    let previousCursor = useRef(null).current;
    let changedCursor = useRef(false).current;

    useEffect(() => {
        setInitialFetchTriggered(true);
        dispatch(fetchViewerGames(null))

        return () => {
            setInitialFetchTriggered(false);
        }
    }, []);

    useEffect(() => {
        setShowRules(showRulesDirectly);
    }, [showRulesDirectly]);

    useEffect(() => {
        changedCursor = previousCursor !== cursor;
        previousCursor = cursor;
    }, [cursor]);

    let leadingTeamScore = null;
    let leadingTeamIndex = null;
    let previousTeamScore = null;
    let tied = true;
    const totalScores = game.gameTeams.map((team, i) => {
        const teamTotalScore = team.gameTeamScores.reduce((acc, gts) => acc + gts.baseCount + gts.secondCount + gts.bonusCount, 0);
        if (leadingTeamScore === null || teamTotalScore > leadingTeamScore) {
            leadingTeamScore = teamTotalScore;
            leadingTeamIndex = i;
        }

        tied = tied && (previousTeamScore === null || (teamTotalScore === previousTeamScore));
        previousTeamScore = teamTotalScore;

        return teamTotalScore;
    });

    const otherGames = games.filter((item) => item.node.id !== game.id);

    const handleGameItemPress = (game) => {
        onGameSwitched();
        navigation.navigate('GameTable', {
            id: game.alphanumericId
        });
    };

    const handleEndReached = () => {
        if (changedCursor && cursor) {
            changedCursor = false;
            dispatch(fetchViewerGames(cursor));
        }
    };

    const handleRulesButtonPress = () => {
        Analytics.logEvent(EVENTS.BUTTON_PRESS, {name: 'rules'});
        setShowRules(true);
    };

    const handleOnRulesBackButtonPress = () => {
        setShowRules(false);
        if (showRulesDirectly) {
            onClosePress();
        }
    };

    const handleOnTutorialPress = () => {
        Analytics.logEvent(EVENTS.BUTTON_PRESS, {name: 'tutorial'});
        navigation.navigate('Tutorial', {reset: 1, from_score: 1, game_id: game.alphanumericId})
    };

    const addThousandsCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
        <View style={{...ownStyles.scoreBoardContainer}}>

            {showRules === true && (
                <View style={{...ownStyles.rulesContainer}}>
                    <GameConfigSummary game={game} onBackButtonPress={handleOnRulesBackButtonPress}
                                       accessToken={accessToken}/>
                </View>
            )}

            {showRules === false && (
                <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                    <View style={{position: 'absolute', top: 15, left: 15, zIndex: 1, alignItems: 'start'}}>
                        <IconButton icon={<CrossIcon size={24} color={Colors.onBackground}/>} onPress={onClosePress}/>
                        <TouchableOpacity style={{...ownStyles.rulesButton}} onPress={handleRulesButtonPress}>
                            <InfoIcon size={24} color={'rgba(70, 74, 83, 1)'}/>
                            <StandardText style={{...ownStyles.rulesButtonText}}>Rules</StandardText>
                        </TouchableOpacity>

                        <TouchableOpacity style={{...ownStyles.rulesButton, marginTop: 10}}
                            onPress={handleOnTutorialPress}>
                            <TutorialIcon size={21} color={'rgba(70, 74, 83, 1)'}/>
                            <StandardText style={{...ownStyles.rulesButtonText}}>Tutorial</StandardText>
                        </TouchableOpacity>
                    </View>

                    <View style={{position: 'absolute', top: 15, right: 15, zIndex: 1}}>
                        <IconButton icon={<HomeIcon size={24} color={Colors.onBackground}/>} onPress={onGoHomePress}/>
                    </View>

                    <View style={{maxHeight: otherGames.length > 0 ? 520 : 'initial', paddingBottom: Spacing.large}}>
                        <ScrollView contentContainerStyle={{alignItems: 'center'}}>

                            <View style={{marginTop: 30}}>
                                <StandardText style={{fontSize: 14, color: Colors.onBackground3, textAlign: 'center'}}>
                                    Game ID: {game.id} {DEBUG && (<> / {APP_VERSION}</>)}
                                </StandardText>
                                <StandardText style={{fontSize: 14, color: Colors.onBackground3, textAlign: 'center', marginTop: 5}}>Round {GameHelper.getRoundPoints(game.currentRound)}</StandardText>
                            </View>

                            <View style={{...ownStyles.scoreBoardWrapper}}>
                                <View style={{...ownStyles.scoreDetailsContainer, paddingTop: 70}}>

                                    <View style={{marginTop: 40, alignSelf: 'stretch'}}>
                                        <ScoreTable game={game} bareBadges={false} showCanastas={true}/>
                                    </View>
                                </View>
                                <View style={{...ownStyles.tableRow, backgroundColor: Colors.inverseBackground}}>
                                    <View style={{...ownStyles.tableColumn, textAlign: 'left'}}>
                                        <StandardText style={{...ownStyles.totalCellText}}>Total</StandardText>
                                    </View>
                                    {totalScores.map((totalScore, i) => (
                                        <View key={i} style={{
                                            ...ownStyles.tableColumn,
                                            flexDirection: 'row',
                                            justifyContent: 'center'
                                        }}>
                                            <StandardText
                                                style={{...ownStyles.totalCellText, ...(!tied && leadingTeamIndex === i ? {color: Colors.success2} : {}), ...(game.hasEnded && !tied && leadingTeamIndex === i ? {marginLeft: 25} : {})}}>{addThousandsCommas(totalScore)}</StandardText>
                                            {(game.hasEnded && !tied && leadingTeamIndex === i) && (
                                                <View style={{marginLeft: 5}}>
                                                    <FlagIcon size={20}
                                                              color={!tied && leadingTeamIndex === i ? Colors.success2 : Colors.success3}/>
                                                </View>
                                            )}
                                        </View>
                                    ))}
                                </View>
                            </View>
                        </ScrollView>
                    </View>

                    {
                        (otherGames.length > 0) &&
                        (<View style={{...ownStyles.totalContainer}}>
                            <StandardText
                                style={{
                                    fontSize: 16,
                                    weight: 500,
                                    color: Colors.onInverseBackground,
                                    textAlign: 'center'
                                }}>Switch
                                to other games</StandardText>

                            <View style={{flex: 1, alignItems: 'center', marginTop: Spacing.base}}>
                                <GamesList games={otherGames}
                                           loading={!initialFetchTriggered || gamesLoading}
                                           onGameItemPress={handleGameItemPress}
                                           onEndReached={handleEndReached()}
                                           paddingBottom={0}
                                           activityIndicatorColor={Colors.onInverseBackground}/>
                            </View>
                        </View>)
                    }
                </View>
            )}


        </View>
    );
};

const ownStyles = {
    scoreBoardContainer: {
        flex: 1,
        alignItems: 'stretch'
    },
    rulesContainer: {
        position: 'fixed',
        left: 0,
        right: 0,
        height: '100%',
        zIndex: 2,
        flex: 1,
        alignItems: 'stretch',
        backgroundColor: Colors.background
    },
    scoreBoardWrapper: {
        width: '100%',
        maxWidth: 450,
        paddingStart: 20,
        paddingEnd: 20,
    },
    scoreDetailsContainer: {
        flex: 1,
        alignItems: 'center',
        paddingBottom: 10
    },
    totalContainer: {
        flex: 1,
        position: 'relative',
        backgroundColor: Colors.inverseBackground,
        alignItems: 'stretch',
        padding: Spacing.large
    },
    tableRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: Spacing.base,
        paddingBottom: Spacing.base,
        paddingLeft: Spacing.base,
        paddingRight: Spacing.base,
        borderRadius: 2
    },
    tableColumn: {
        flex: 1,
        textAlign: 'center',
    },
    totalCellText: {
        color: Colors.background,
        fontSize: 20,
        weight: 500
    },
    rulesButton: {
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'rgba(242, 242, 242, 1)',
        padding: 8,
        alignItems: 'center',
        marginTop: Spacing.base
    },
    rulesButtonText: {
        color: 'rgba(77, 77, 77, 1)',
        fontSize: 14,
        marginStart: 10
    },
};

const mapStateToProps = (state) => {
    return {
        games: state.viewer.games.data,
        cursor: state.viewer.games.cursor,
        gamesLoading: state.viewer.games.loading,
        gamesError: state.viewer.games.error,
        accessToken: state.auth.login.accessToken,
    }
};

export default connect(mapStateToProps)(ScoreBoardInGame);
